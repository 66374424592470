.sidebar{
    width: 350px;
    height: 100vh;
    border-right: 2px solid #8D8D8D;
    position:fixed;
}

.active{
    background-color: #BABBBE;
    border: none;
}

.menu{
    padding: 20px 20px;
}
.menu-logo{
    margin-bottom: 30px;
}

.menu-item{
    display: flex;
    margin-left: 0;
    padding-left: 0;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
    border-bottom: 2px solid #8D8D8D;
    
}
.menu-item:hover{
    cursor: pointer;
    
}

.menu-item-icon{
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.menu-item-text{
    font-family: 'Outfit',sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin: 0 
}

@media (max-width:480px) {
    .sidebar{
        display: none;
        background-color: #FFFFFF;
        width: 100% !important;
    }
    .menu{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}