.admin-page{
    display: flex;
}
.left-side{
    width: 100%;
    margin-left: 350px;
}
.content{
    padding: 20px 120px;
}

@media (max-width:480px) {
    .left-side{
        margin-left: 0;
    }
    .content{
        padding: 5px 5px;
    }
}
