.hero-container{
    margin: 30px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hero-checkbox{
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
}

.fourth-section-two-icon{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cursor-icon{
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-top: 40px;
}

.exclamation-icon{
    width: 30px;
    height: 100px;
    margin-right: 50px;
    margin-top: 40px;
}

.circle-around-icon{
    width: 60px;
    height: 60px;
    margin-right: 30px;
    margin-top: 40px;
}

.diamond-icon{
    width: 80px;
    height: 80px;
    margin-top: -40px;
}

.container-global{
    margin: 0px 280px;
    
}

.hero-text{
    font-weight: 600;
    font-size: 52px;
    text-align: center;
    color: #0A0A0C;
    font-family: 'Outfit', sans-serif;
    line-height: 90.72px;
}

.red-text{
    color: #F4444C;
}

.col-start{
    min-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.col-start img{
    width: 200px;
}
.col-end img{
    width: 250px;
}
.col-end{
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.col-middle{
    margin-top: 25px;
}

.second-section{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;


}

.red{
    color: #F4444C;
}
.subtitle-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.subtitle{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    text-align: center;
    margin: 0px;
    font-weight: bold;
}
.small-subtitle{
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    margin: 0;
    text-align: center;
}

.hero-input{
    border: none;
    font-family: 'Outfit', sans-serif;
}

.hero-input:focus{
    border: none;
    outline: none;
    font-family: 'Outfit', sans-serif;
}

.hero-email-container{
    border: solid 2px #8D8D8D;
    border-radius: 10px;
    display: flex;
    width: fit-content;
    padding: 10px 30px;
    justify-content: space-between;
    margin: auto;
}

.hero-button{
    border-radius: 10px;
    background-color: #F4444C;
    padding: 5px 30px;
    color: #F6F6F6;
    margin-left: 20px;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
}

.video-section{
    background-color:#0A0A0C ;
    height: 100;
    width: 100%;
    padding: 10px 20px;
}

video{
    object-fit: fill;
}

.third-section{
    margin-top: 40px;
    width: 100%;
    padding-bottom: 100px;
}

.third-section-title{
    font-weight: 600;
    font-size: 32px;
    font-family: 'Outfit', sans-serif;
    text-align: center;
    margin-bottom: 50px;

}

.third-section.row{
    margin-top: 30px;
}

.third-section-image{
    width: 180px;
    height: 180px;
}


.col div{
    text-align: center;
}

.col div p{
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
}

.fourth-section{
    padding: 10px 0px;
}

.text-card{
    background-color:#F4444C ;
    border-radius: 0 0px 15px 15px;
    margin-top: -10px;
    height: 160px;
    width: 200px;
    position: relative;
    z-index: 3;
}
.card-ai{
    margin: 10px;
}

.card-ai-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.text-card-title{
    font-weight: 600 !important;
    font-size: 32px !important;
    font-family: 'Outfit', sans-serif;
    color: #F6F6F6;
    margin: 0px 10px;
    text-align: center;
}
.text-card-subtitle{
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: 'Outfit', sans-serif;
    color: #F6F6F6;
    margin: 10px 10px;
    text-align: center;
}

.ai-image{
    width: 200px;
    z-index: -1;
}

@media(max-width:450px){
    .second-section{
        display: block;
        margin: auto;
    }
    .hero-text{
        font-size: 30px;
        line-height: normal;
    }
    .hero-container{
        margin: 20px 10px;
    }

    .col-start{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: auto;
    }
    .col-end{
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto !important;
        margin:30px 0px
    }
   .container-global{
    overflow-x: hidden;
    margin: 0px 40px;
   }
   .hero-email-container{
    width: 100%;
   }

    .col-start img{
        width: 120px;
    }
    .col-end img{
        width: 140px;
    }
    .subtitle{
        font-size: 20px;
    }
    .col img{
        width: 72px;
        height: 72px;
    }

    .col div p{
        font-family: 'Outfit', sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .fourth-section{
        padding: 10px 10px;
    }
    .text-card{
        padding: 0px 10px;
        height: 100px;
    }
    .text-card-subtitle{
        font-size: 10px !important;
    }
    .text-card-title{
        font-size: 14px !important;
    }
    .card-ai{
        width: 50% !important;
        flex: none;
    }
}