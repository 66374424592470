.generate-container{
    padding: 30px 40px;
}

.loading-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.loading-animation{}

.generate-second-row{
    margin-top: 30px;
}

.loading-text{
    color: #0A0A0C;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    margin: 0px;
    margin-top: -20px;
}

.empty-alert{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    margin: 0px;
    margin-top: 10px;
    color: #F4444C;
}

.generate-second-row p{
    margin: 0;
}

.generate-third-row{
    margin-top: 30px;
    border-bottom: 2px solid #8D8D8D;
    padding-bottom: 20px;
}

.normal-text{
    color: #0A0A0C;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
}
.generate-box-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.generate-box{
    border: 2px solid #8D8D8D;
    border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
}

.generate-main-divider{
    width: 100%;
    height: 2px;
    background-color: #8d8d8d;
    margin-top: 10px;
}

.generate-box-text{
    margin: 0;
}

.generate-checkbox-container{
    display: flex
}

.generate-space{
    width: 20px;
}

.generate-button-container{
    display: flex;
    flex-direction: row;
}

.form-check{
    margin-right: 20px;
}

.result-card{
    display: flex;
    padding-bottom: 20px;
    border-bottom:  2px solid #8D8D8D;
    margin-top: 20px;
    cursor: pointer;
}

.generate-four-row{
    margin-top: 30px;
}

.result-card img{
    width: 200px;
    height: 200px;
}

.result-content{
    margin-left: 30px;
}

.generate-divider {
    height: 2px;
    background-color: #8D8D8D;
    margin-top: 10px;
    margin-bottom: 10px;
}

.generate-preview-content{
    padding: 10px 60px;
}

.generate-preview-content-image-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.generate-preview-content-image{
    max-width: 100%;
}

.generate-input-prompt-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.generate-divider{
    width: 30px;
    border: solid 0px'none';
    background-color:transparent;
}

@media (max-width:480px) {
    .generate-container{
        padding: 0px;
        width: 90%;
        margin: auto;
    }
    .result-card{
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .result-content{
        width: 100%;
    }
    .generate-preview-content-image{
        width: 300px;
    }
    .generate-preview-content{
        width: 100%;
    }
}