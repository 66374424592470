.pricing-list-container{
    display: flex;
    justify-content: space-between;
}
.content-row{
    margin-top: 50px;
    padding: 20px 50px;
}
.pricing-content-title{
    font-family: 'Outfit',sans-serif;
    font-weight: 600;
    text-align:center;
    font-size: 73px;
    margin: 20px 20px;
}
.comparison-header{
    font-family: 'Outfit',sans-serif;
    font-weight: 600;
    font-size:35px;
    margin: 20px 20px;
    color: #F4444C;
}
.comparison-item{
    font-family: 'Outfit',sans-serif;
    font-weight: 400;
    font-size:35px;
    margin: 20px 20px;
    color: #8D8D8D;
}
.comparison-item-icon{
    font-family: 'Outfit',sans-serif;
    font-weight: 400;
    font-size:35px;
    margin: 20px 20px;
    color: #0A0A0C;
}