.generate-upload-schedule-post{
    margin-top: 30px;
}

.generate-upload-form-modal-date-and-time-container{
    display: flex;
    flex-direction: row;
    gap: 150px;
    margin-top: 20px;
}

.generate-upload-social-media-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.generate-upload-social-media-icon{
    font-size: 45px ;
    color: #8d8d8d;
    cursor: pointer;
}

.generate-upload-social-media-icon.active{
    font-size: 45px ;
    color: #F4444C;
    cursor: pointer;
    background-color: #FFFFFF;
}

.generate-upload-text{
    font-family: 'Outfit', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.generate-upload-button-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 30px;
}