.text-title{
    font-family: 'Outfit',sans-serif;
    font-weight: 600;
    text-align:center;
}

.about-page-card{
    background-color: #D6D6D8;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    margin: 20px 0;
}
.col-about{
    margin: 10px 40px;
}

.title-card-about{
    font-family: 'Outfit', sans-serif;
    font-size: 35px !important;
    font-weight: 600;
    text-align: left !important;
}
.subtitle-card-about{
    font-family: 'Outfit', sans-serif;
    font-size: 20px !important;
    font-weight: 400;
    text-align: left !important;
}

.about-image{
    width: 100%;
}