.redirected-container{
    margin: auto;
    width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
}

.redirect-text{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
}