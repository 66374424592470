.term-container{
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.term-container p{
    color: #8D8D8D;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Outfit',sans-serif;
    margin-left: 10px;
}
.term-container p:hover{
    cursor: pointer;
}

@media(max-width:480px){
    .term-container p{
        color: #8D8D8D;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Outfit',sans-serif;
        margin-left: 10px;
    }
}