.modal-free-unlock-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-free-unlock-title{
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom: 2px solid #8d8d8d8d;
}

.modal-free-unlock-subtitle{
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
}