.search-first-row{
    display: flex;
    margin-top: 30px;
}
.search-first-row span{
    margin-left: 20px;
}

.search-second-row{
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #8d8d8d;
}

.search-checkbox-container{
    display: flex
}

.search-checkbox{
    margin-right: 50px;
}
.search-result-card{
    display: flex;
    padding-bottom: 20px;
    border-bottom:  2px solid #8D8D8D;
    margin-top: 40px;
}

.search-detail-card{
    display: flex;
    padding-top: 20px;
    border-top:  2px solid #8D8D8D;
    margin-top: 40px;
}

.search-result-card img{
    width: 200px;
    height: 200px;
}

.search-detail-card img{
    width: 250px;
    height: 250px;
}

.description-container{
    margin-top: 20px;
}

.description-container p{
    margin: 0;
}

.search-result-content{
    margin-left: 30px;
}
.search-result-content p{
    margin: 0;
}


.search-detail-container{
    display: none;
}

.title-text{
    color: #0A0A0C;
    font-size: 60px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    margin: 0;
}

.search-detail-img{
    width: 300px;
    height: 300px;
}

.search-detail-button-container{
    display: flex;
    margin-top: 30px;
}

.search-detail-button-container span{
    margin-left: 20px;
}