.container-global-navbar{
    margin: 0px 40px;
}

nav{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

nav ul{
    display: flex;
}

nav ul li{
    list-style: none;
}

nav ul li a{
    display: block;
    text-decoration: none;
    color: #0A0A0C;
    padding: 0.5rem;
    margin: 0 0.5rem;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

nav .menu{
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    /* width: 2.25rem; */
    height: max-content;
}


@media (max-width:480px){
    .container-global-navbar{
        margin: 0 0;
    }
    nav{
        width: 100%;
    }
    nav .menu{
        display: flex;
    }
    nav{
        flex-direction: column;
        align-items: start;
        justify-content: center;
        align-items: center;
    }
    nav ul{
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }
    nav ul.open{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    nav ul li{
        width: 100%;
        text-align: center;
    }
    nav ul li a{
        margin: 0.2rem 0.5rem;
    }

}