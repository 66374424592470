.chat-page{
    display: flex;
}
.right-side{
    width: 100%;
    margin-left: 350px;
    height: 100vh;
}
.top-side{
    width: 100%;
    height: 10%;
}
.chat-content{
    height: 90%;
}
.chat-container{
    height: 80%;
    overflow-y: scroll;
}
.chat-bottom{
    display: flex;
    padding: 20px 30px;
    justify-content: space-between;
    align-items: center;
    background-color:#D6D6D8 ;
    bottom: 0;
    height: 20%;
}

.chat-input-container{
    border: 2px solid #d8d8d8;
    display: flex;
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}
.chat-input{
    width: 80%;
}

.chat-input-container textarea{
    border: none;
    width: 90%;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;

}

.icon-holder{
    width: 45px;
    height: 45px;
    border-radius: 23px;
    background-color: black;
    align-items: center;
    display: flex;
    justify-content: center;
}
.icon-holder-white{
    width: 45px;
    height: 45px;
    border-radius: 23px;
    background-color: #D6D6D8;
    align-items: center;
    display: flex;
    justify-content: center;
}