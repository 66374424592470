.top-bar-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 20px;
}
.top-bar{
    width: 100%;
}

.top-bar-right{
    display: flex;
    align-items: center;
}

.top-bar-right-item{
    margin-right: 30px;
}
.profile{
    display: flex;
    align-items: center;
}
.profile p{
    margin: 0 0 0 10px;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.menu-popover-item{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.menu-popover-item p{
    margin: 0;
    font-family: 'Outfit',sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.menu-popover-item img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.top-bar-avatar{
    width: 45px;
    height: 45px;
    border-radius: 23px;
}

@media (max-width:480px) {
    .top-bar-left{
        display: none;
    }
    .top-bar-right{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .profile p{
        margin: 0 0 0 5px;
        display: none;
    }
}