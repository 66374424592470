.login-container{
    height: 100vh;
    width: 100%;
}

.login-form-container{
    display: block;
}

.sign-up{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #0A0A0C;
}

.sign-up span{
    color: #F4444C; 
}
.sign-up span:hover{
    cursor: pointer;
}

.login-subtitle{
    font-family: 'Outfit', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #0A0A0C;
}



.center-container{
   text-align: center;
   margin: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 90vh;
}

