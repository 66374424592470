.title-card-text{
    font-family: 'Outfit', sans-serif;
    font-size: 75px !important;
    font-weight: 600 !important;
    text-align: left !important;
}
.highlight-card-text{
    font-family: 'Outfit', sans-serif;
    font-size:20px !important;
    font-weight: 400 !important;
    color: #8D8D8D;
}
.price-row-one{
    display: flex;
    width: 70%;
}
.subtitle-card-text{
    font-family: 'Outfit', sans-serif;
    font-size: 35px !important;
    font-weight: 600 !important;
    text-align: left !important;
}
.card-feature-text{
    font-family: 'Outfit', sans-serif;
    font-size: 20px !important;
    font-weight: 400 !important;
    text-align: left !important;
}

.best-offer-container{
    background-color: #F4444C;
    padding: 10px 20px;
    margin: 0px 10px;
    margin-top: -20px;
    border-radius: 0 0 10px 10px;
}
.best-offer-text{
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
}

.price-card{
    border: 2px solid #8D8D8D;
    border-radius: 10px;
    margin: 10px 10px;
    padding: 20px 20px;
    width: 500px;
}
.image-space{
    height: 300px;
}