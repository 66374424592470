.edit-main-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-main-image{
    width: 300px;
}

.edit-main-caption{
    padding: 10px 10px;
    margin-left: 30px;
    border: solid 2px #8d8d8d;
    border-radius: 10px;
    width: 600px;
    height: 300px;
}

.edit-regenerate-image-button-container{
    margin-top: 20px;
}
.edit-regenerate-captions-button-container{
    margin-top: 50px;
}

.edit-regenerate-image-container{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.edit-regenerate-text-container{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}

.edite-regenaret-title{
  margin: 0;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
}

.edit-regenerate-text-row{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.edit-regenerate-text-caption{
  margin: 0;
  font-family: 'Outfit', sans-serif;
}

.edit-regenerate-item{
  border-bottom: solid 2px #8d8d8d;
  margin-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.edit-regenerate-image-result{
    width: 200px;
    
}

.image-overlay {
    position: relative;
    overflow: hidden; /* Hides the overlay by default */
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  .image-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(244, 68, 76, 0.5); /* 50% black using rgba() */
    opacity: 0; /* Initially invisible */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
  }
  .image-overlay.active::before {
    opacity: 1; /* Make the overlay visible when the 'active' class is present */
  }
  .edit-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .image-overlay.active .edit-icon-container {
    opacity: 1;
  }

  .edit-icon-{
    font-size: 32px;
    color: #FFFFFF;
  }

  .edit-regenerate-button-container{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 20px;
  }

  @media (max-width:480px) {
    .edit-main-container{
      flex-direction: column;
      width: 100%;
    }
    .edit-main-caption{
      width: 400px;
      margin-left: 0px;
      margin-top: 10px;
    }
  }