.get-started-container{
    background-color: #0A0A0C;
    height: 100vh;
    width: 100%;
}

.get-started-button-container{
    display: flex;
    justify-content: space-between;
}


.get-started-subtitle{
    font-family: 'Outfit', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #F6F6F6;
}



.center-container{
   text-align: center;
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 90vh;
}

.get-started-button-container br{
    display: none;
}

.get-started-modal{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.get-started-modal-avatar{
    width: 72px;
    height: 72px;
}

.get-started-modal-title{
    font-family: 'Outfit', sans-serif;
    font-size: 30px;
    font-weight: 600px;
    margin: 20px 0px;
}
.get-started-modal-button{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400px;
    margin: 20px 0px;
    border: 2px solid #0A0A0C;
    border-radius: 10px;
    padding: 10px 10px;
    width: fit-content;
    cursor: pointer;
}

.get-started-modal-three-dot{
    display: flex;
    margin-top: 30px;
}

.get-started-divider{
    margin: 50px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.get-started-divider-line{
    height: 2px;
    background-color: #fff;
    width: 40%;
}
.get-started-divider-text{
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    color: #fff;
}

.get-started-modal-three-dot span{
    margin-left: 15px;
    background-color: #D6D6D8;
    width: 15px;
    height: 15px;
    border-radius: 7px;
}

.three-dot-active{
    background-color: #F4444C !important;
}

.social-media-login-container{

}

.social-media-login-button{
    display: flex;
    padding: 10px 20px;
    border-radius:10px;
    margin: 10px 0px;
    justify-content: center;
    align-items: center;
    background-color: #F4444C;
    cursor: pointer;
    width: 100%;
}
.social-media-login-button-text{
    font-family: 'Outfit', sans-serif !important;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 0 10px;
    color: #fff;
}
.social-media-login-button-icon{
    color: #fff;
}

@media(max-width:450px){
    .get-started-button-container{
        display: block;
     
    }
    .get-started-button-container br{
        display: block;
    }
}