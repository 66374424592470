.modal-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 30px;
    border-bottom: 2px solid #8d8d8d;
}

.modal-header-container p{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: black;
    margin: 0;
}

.modal-body-container{
    margin: 10px 30px;
}
