.signup-container{
    height: 100vh;
    width: 100%;
}

.signup-form-container{
    display: block;
}

.login{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #0A0A0C;
}

.login span{
    color: #F4444C; 
}
.login span:hover{
    cursor: pointer;
}

.signup-subtitle{
    font-family: 'Outfit', sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #0A0A0C;
}



.center-container{
   text-align: center;
   margin: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 90vh;
}

.sign-up-three-dots{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sign-up-dots{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #8d8d8d;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.sign-up-dots.active{
    background-color: #F4444C;
}

.sign-up-privacy{
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: solid 2px #8d8d8d;
    padding: 10px;
    border-radius: 10px;
    height: 300px;
    overflow: auto;
}

.sign-up-privacy-text{
    max-height: 100%;
    white-space: pre-wrap;
    text-align: left;
    font-family: 'Outfit', sans-serif;
}

.flags{
    border: solid 2px #8d8d8d !important;
    border-radius: 10px !important;
    padding: 0px !important;
}
.flags button{
    border: none;
    font-family: 'Outfit', sans-serif;
    font-size: 20px !important;
}

.select-sign-up{
    border: solid 2px #8d8d8d !important;
    border-radius: 10px !important;
    padding: 12px !important;
}

.checklist-sign-up{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.sign-up-third-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text{
    font-family: 'Outfit', sans-serif;
}

.sign-up-bottom-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}

@media (max-width:480px) {
    .sign-up-first-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sign-up-second-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sign-up-third-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .flags{
        width: 350px;
    }
    .select-sign-up{
        width: 350px;
    }
    .sign-up-privacy{
        width: 380px;
    }
    .checklist-sign-up{
        width: 380px;
    }
}