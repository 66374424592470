.sidebar{
    width: 350px;
    height: 100vh;
    border-right: 2px solid #8D8D8D;
    position:fixed;
}

.active{
    background-color: #BABBBE;
    border: none;
}

.menu{
    padding: 20px 20px;
    height: 90%;
    max-height: 90%;
    overflow-y: scroll;
    
}
.menu-logo{
    margin-bottom: 30px;
}

.menu-item{
    display: flex;
    margin-left: 0;
    padding-left: 0;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
    border-bottom: 2px solid #8D8D8D;
    
}
.menu-item:hover{
    cursor: pointer;
    
}

.menu-item-icon{
    width: 25px;
    height: 25px;
    margin-right: 20px;
}

.menu-item-text{
    font-family: 'Outfit',sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin: 0 
}

.profile-pic{
    width: 45px;
    height: 45px;
    border-radius: 23px;
}

.account-menu{
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-menu p{
    margin: 0 10px 0 10px;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.bottom-menu{
    bottom: 0;
    height: 100%;
    border-top: 2px solid #d8d8d8;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}