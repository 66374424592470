.storage-first-row{
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #d8d8d8;
    padding-bottom: 20px;
    margin-bottom: 10px;
    align-items: center;
}

.storage-progress-bar{
    width: 250px;
    height: 25px;
    border-radius: 20px;
    background-color: #D8d8d8;
    display: flex;
}

.progress-text-container{
    display: flex;
    justify-content: space-between;
}

.progress-text-container p{
    font-size: 10px;
    margin: 0 0 5px 0;
    font-family: 'Outfit', sans-serif;
}

.legend-container{
    display: flex;
    justify-content: space-between;
}

.legend-item{
    display: flex;
    padding: 5px 5px;
}

.legend-item-box{
    width: 15px;
    height: 15px;
    border-radius: 5px;
    background-color: #0A0A0C;
    margin-right: 5px;
}

.legend-item p{
    font-size: 10px;
    margin: 0 0 5px 0;
    font-family: 'Outfit', sans-serif;

}

.storage-second-row{
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.icon-container{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin-left: 20px;
}

.storage-table-header{
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #d8d8d8;
}

.col p{
    text-align: center;
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
}
.table-data-text{
    text-align: center;
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    margin-left: 10px;
    max-width: 100%;
}

.access-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-container{
    border: none;
    text-align: center;
}

.checkbox-container{
    display: flex !important;
}

.storage-action-container{
    display: flex;
    align-items: center;
}

.storage-table-data{
    margin-top: 10px;
    align-items: center;
}

.storage-table-data-container{
    padding-bottom: 20px;
    border-bottom: 2px solid #d8d8d8;
}
.small-text{
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}
.storage-four-row{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

.storage-page-button{
    cursor: pointer;
}

.storage-five-row{
    display: flex;
    margin-top: 20px;

}
.storage-five-row span{
    margin: 10px;
}

.storage-detail-first-row{
    display: flex;
    align-items: center;
}
.storage-detail-first-row p{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #8d8d8d;
    margin: 0;
}

.storage-detail-table-data{
    align-items: start;
    padding-top: 20px;
}

.storage-detail-four-row{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.storage-detail-five-row{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.storage-detail-five-row span{
    margin-left: 20px;
}