.select-drop-down-field{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;
    border: 1px solid #8d8d8d;
    border-radius: 10px;
    cursor: pointer;
    justify-content: space-between;
}
.select-drop-down-field-left{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select-drop-down-options-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.select-drop-down-option{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px;
}

.select-drop-down-option:hover{
    cursor: pointer;
    background-color: #8d8d8d;
}
.select-drop-down-option.active{
    cursor: pointer;
    background-color: #5f9ae4;
}

.select-drop-down-field-icon{
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.select-drop-down-field-text{
    font-family: 'Outfit', sans-serif;
    margin: 0;
    font-size: 16px;
}

.dropdown-icon{
    transition-duration: 0.5s;
}

.dropdown-icon.active{
    transform: rotate(180deg);
    transition-duration: 0.5s;
    background-color: transparent !important;
}