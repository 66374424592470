.settings-modal-button-container{
    display: flex;
}
.settings-modal-chooser-dark{
    margin: 10px 10px;
    border-radius: 10px;
    background-color: #D6D6D8;
    content: #31343C;
    padding: 10px 20px;
}
.settings-modal-chooser-dark p{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}
.settings-modal-chooser-light p{
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}
.settings-modal-chooser-light{
    margin: 10px 10px;
    border-radius: 10px;
    background-color: #31343C;
    color: #F6F6F6;
    padding: 10px 20px;
}

.settings-modal-second-row{
    margin-top: 20px;
}
.settings-modal-second-row p{
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.settings-modal-input-container{
    margin-top: 20px;
}
.settings-modal-input-container p{
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.settings-modal-third-row{
    margin-top: 30px;
}
.settings-modal-third-row-item{
    display: flex;
    justify-content: space-between;
    margin: 20px 20px;
    align-items: center;
}

.settings-modal-third-row-item p{
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.delete-modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-modal-container p{
    margin: 0;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    font-weight: 400;
}